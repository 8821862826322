<script setup lang="ts">
import { computePosition, flip, shift, offset } from '@floating-ui/vue'

interface Props {
  content: string
  showOn?: boolean
  onOverflow?: boolean
  maxWidth?: string
}

const props = withDefaults(defineProps<Props>(), {})

const referenceElement = ref<HTMLDivElement | null>(null)
const floatingElement = ref<HTMLDivElement | null>(null)

const hidden = ref(true)

const calculatePosition = async () => {
  if (!referenceElement.value || !floatingElement.value) { return }
  const position = await computePosition(
    referenceElement.value as HTMLDivElement,
    floatingElement.value as HTMLDivElement,
    {
      placement: 'bottom',
      middleware: [offset(4), flip(), shift({ padding: 4 })],
    },
  )

  floatingElement.value!.style.left = `${position.x}px`
  floatingElement.value!.style.top = `${position.y}px`
}

const hide = () => {
  hidden.value = true
}

const isOverflowing = computed(() => {
  if (!referenceElement.value) { return false }
  const isHorizontallyOverflowing = referenceElement.value.scrollWidth > referenceElement.value.clientWidth
  const isVerticallyOverflowing = referenceElement.value.scrollHeight > referenceElement.value.clientHeight
  return isHorizontallyOverflowing || isVerticallyOverflowing
})

const show = () => {
  if (props.showOn) {
    return
  }
  if (props.onOverflow && !isOverflowing.value) {
    return
  }
  hidden.value = false
  calculatePosition()
}

const onHover = ref(false)
const mouseEnter = () => {
  onHover.value = true
  show()
}

const mouseLeave = () => {
  onHover.value = false
  hide()
}

watch(() => props.showOn, () => {
  if (!onHover.value) { return }
  show()
})
</script>

<template>
  <div class="inline-block">
    <div
      ref="referenceElement"
      class="tooltip-container"
      v-bind="$attrs"
      @mouseenter="mouseEnter"
      @mouseleave="mouseLeave"
    >
      <slot />
    </div>
    <div
      v-show="!hidden"
      ref="floatingElement"
      class="tooltip"
      :style="{
        'max-width': maxWidth ?? undefined,
      }"
    >
      {{ props.content }}
    </div>
  </div>
</template>

<style scoped lang="postcss">
.tooltip {
  @apply absolute left-0 top-0 shadow-large-dense px-2 py-1 bg-black-100 rounded-sm text-white-100 normal-case text-body-regular-12 break-words max-w-60 whitespace-pre-wrap;
  &-container {
    @apply cursor-pointer;
    overflow: inherit;
    text-overflow: inherit;
    white-space: inherit;
  }
}
</style>
